import liff from "@line/liff";
import { useEffect, useState } from "react";

function App() {
  const [profile, setProfile] = useState({});

  const lineInit = async () => {
    try {
      await liff.init({ liffId: "1657004016-MYA9P9lp" });
    } catch (error) {
      console.log("liff error");
    }
    if (!liff.isLoggedIn()) {
      liff.login();
    }
  };

  const getProfile = async () => {
    await liff.ready;
    const profile = await liff.getProfile();
    setProfile(profile);
  };

  const closeWindow = () => {
    liff.closeWindow();
  };
  const getCode = async () => {
    try {
      const result = await liff.scanCodeV2();
      await liff.sendMessages([
        {
          type: "text",
          text: result.value,
        },
      ]);

      liff.closeWindow();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    lineInit();
    getProfile();
    getCode();
  }, [profile.userId]);

  return (
    <div>
      <h1>Bootstrapping App......please wait</h1>
      <p>{profile.displayName}</p>
      <button onClick={closeWindow}>Close windows</button>
    </div>
  );
}

export default App;
